import React, { useRef } from "react";
import { Col, Row, Button, Form, FormGroup, Label, Input } from "reactstrap";
import ReCAPTCHA from "react-google-recaptcha";
import email from "@emailjs/browser";

const ContectForm = (props) => {
  const form = useRef();
  function onChange(value) {
    console.log("Captcha value:", value);
  }

  function onSubmit(e) {
    e.preventDefault();
    console.log("Submitting");
    const SERVICEID = "service_k8dcxff";
    const TEMPLATEID = "template_8ls3tbh";
    const PUBLICKEY = "pDK_XIH2GNzD0qzzB";
    email
      .sendForm(SERVICEID, TEMPLATEID, e.target, PUBLICKEY)
      .then((res) => console.log("Success", res))
      .catch((err) => console.log("Error", err));
  }

  return (
    <Form
      ref={form}
      className="About-main-form-input-2  pb-5 pl-5 pr-5"
      onSubmit={onSubmit}
    >
      <Row form className="container-fluid">
        <Col md={12} sm={12} lg={4}>
          <FormGroup>
            <Label for="exampleEmail">First Name</Label>
            <Input
              hidden
              className="contact-us-form-input"
              type="text"
              name="to_name"
              id="to_name"
              value="Pamela"
              style={{ borderRadius: "24px" }}
            />
            <Input
              className="contact-us-form-input"
              type="text"
              name="from_name"
              id="from_name"
              placeholder="First Name"
              style={{ borderRadius: "24px" }}
              required
            />
          </FormGroup>
        </Col>
        <Col md={12} sm={12} lg={4}>
          <FormGroup>
            <Label for="examplePassword">Email</Label>
            <Input
              className="contact-us-form-input"
              type="mail"
              name="reply_to"
              id="reply_to"
              placeholder="Email address"
              style={{ borderRadius: "24px" }}
              required
            />
          </FormGroup>
        </Col>
        <Col md={12} sm={12} lg={4}>
          <FormGroup>
            <Label for="exampleEmail">Phone</Label>
            <Input
              className="contact-us-form-input"
              type="Phone"
              name="phone_no"
              id="phone_no"
              placeholder="Phone Number"
              style={{ borderRadius: "24px" }}
            />
          </FormGroup>
        </Col>
      </Row>

      <FormGroup className="container-sm">
        <Label for="exampleText">Message</Label>
        <Input
          type="textarea"
          className="contact-us-form-input"
          name="message"
          id="Message"
          placeholder="Message"
          style={{ borderRadius: "24px" }}
          required
        />
      </FormGroup>

      {/* <FormGroup
        className="container-sm"
        style={{
          display: "flex",
          justifyContent: "flex-start",
          marginTop: "2rem",
        }}
      >
        <ReCAPTCHA
          sitekey="6LcV0uchAAAAAOWCxTP8ZVE5XKLBIQDdBppX0Crl"
          onChange={onChange}
        />
        ,
      </FormGroup> */}
      <FormGroup
        className="container-sm"
        style={{ display: "flex", justifyContent: "center", marginTop: "2rem" }}
      >
        <Button
          type="submit"
          className="form-btn"
          style={{ color: "black", border: "2px solid #076332" }}
        >
          submit
        </Button>
      </FormGroup>
    </Form>
  );
};

export default ContectForm;
