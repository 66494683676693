import React from "react";
import From from "./contect-us-form";
const ContectUSmain = () => {
  return (
    <div className="container pt-5">
      <div className="contact-us-margin-class"></div>
      <div className="container contect-us-main-heading">
        <p>
          Send us a <span>message</span>
        </p>
      </div>
      <div className="container contect-us-main-text mb-2 mt-1">
        <p>
          Mendocino is one of the most magical places on Earth. Its residents
          are diverse and unique. Living here is a gift! We are here to help you
          accomplish the dream of making Mendocino your home. Whether you are
          buying or selling a property on our coast, choosing us as your Realtor
          ® is the best decision you’ll make.
        </p>
      </div>
      <From />
    </div>
  );
};

export default ContectUSmain;
